import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { ViewStateParameters } from './view-state-parameters.model';
import { IAccountRead_ResponseData } from '../../gql-operations/account/account-read.query';
import { getViewStateParameters } from './get-view-state-parameters.method';
import { ViewStateParametersRead_Query } from '../../gql-operations/_client/view-state-parameters-read.query';
import { AccountReadService } from '../../gql-operations/account/account-read.service';


/** Consolidates all route parameters on currently active view route into single object published to local GQL query `ViewStateParameterRead` */
@Injectable({
	providedIn: 'root',
})
export class ViewStateService implements OnDestroy {
	public viewStateParameters: ViewStateParameters; // @ToDo: Consider removing snapshot object & reading from Apollo query where referenced
	private isDestroyed: Subject<void> = new Subject();

	public constructor(
		private viewRouter: Router,
		private apollo: Apollo,
		private accountReadService: AccountReadService,
	) {
		this.updateViewStateParametersOnNavigationEnd();
	}

	public ngOnDestroy(): void {
		this.isDestroyed.next();
		this.isDestroyed.complete();
	}

	/** On each Angular Router NavigationEnd, update local GQL query `ViewStateParameterRead` with consolidated route parameter snapshot */
	private updateViewStateParametersOnNavigationEnd(): void {
		this.viewRouter.events.pipe(
			filter((viewRouterEvent) => {
				return viewRouterEvent instanceof NavigationEnd;	// Update ViewStateParameters every time view navigation ends
			}),
			map(() => {
				return getViewStateParameters(this.viewRouter.routerState.snapshot);
			}),
			distinctUntilChanged(),
			mergeMap((viewStateParameters: ViewStateParameters) => {
				if (viewStateParameters.organizationId) {
					return this.accountReadService.fetch({ organizationId: viewStateParameters.organizationId }).pipe(
						map(({ data: { AccountRead } }: FetchResult<IAccountRead_ResponseData>) => {
							viewStateParameters.accountId = AccountRead._id; // add accountId for snapshot, since it's not in the route parameters
							return viewStateParameters;
						}),
						catchError((error: unknown) => {
							return of(viewStateParameters); // Ignore errors so that components dependent on `ViewStateParametersRead` continue to respond to view state changes
						}),
					);
				} else {
					return of(viewStateParameters);
				}
			}),
			takeUntil(this.isDestroyed),
		).subscribe({
			next: (viewStateParameters: ViewStateParameters) => {
				this.viewStateParameters = viewStateParameters;
				this.apollo.client.writeQuery({
					query: ViewStateParametersRead_Query,
					data: { ViewStateParametersRead: viewStateParameters },
				});
			},
		});
	}
}
