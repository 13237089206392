import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';


@Injectable({
	providedIn: 'root',
})
export class CryptoService {
	public constructor(private readonly authenticationService: AuthenticationService) {
	}

	/**
	 * Decrypts AES-GCM ciphertext in the browser using the Web Crypto API.
	 *
	 * @param initializationVectorBase64 - Base64-encoded 12-byte IV.
	 * @param ciphertextAndTagBase64 - Base64-encoded ciphertext + auth tag (16 bytes).
	 * @param encryptionSalt - same as used on server to create key.
	 * @returns The decrypted plaintext as a string.
	 */
	public async decryptAESGCM(
		ciphertextAndTagBase64: string,
		initializationVectorBase64: string,
		encryptionSalt: string,
	): Promise<string> {
		const { accessToken } = await this.authenticationService.accountsClient.getTokens();
		// Convert to Uint8Array
		const accessTokenBytes = new TextEncoder().encode(accessToken);
		const saltBytes = new TextEncoder().encode(encryptionSalt);
		const initializationVectorByteArray = this.base64ToByteArray(initializationVectorBase64);
		const cipherByteArray = this.base64ToByteArray(ciphertextAndTagBase64);
		// Create key material from raw JCT bytes
		const keyMaterial = await crypto.subtle.importKey(
			'raw',
			accessTokenBytes,
			{ name: 'PBKDF2' },
			false,
			['deriveKey'],
		);
		// Create AES-GCM key
		const cryptoKey = await crypto.subtle.deriveKey(
			{
				name: 'PBKDF2',
				salt: saltBytes,
				iterations: 100000,
				hash: 'SHA-256',
			},
			keyMaterial,
			{ name: 'AES-GCM', length: 256 },
			false,
			['encrypt', 'decrypt'],
		);
		// Perform the decryption
		const decryptedBuffer = await crypto.subtle.decrypt(
			{
				name: 'AES-GCM',
				iv: initializationVectorByteArray,
				// If needed, you can specify additionalData or tagLength here.
				// tagLength: 128 // (in bits) default is 128 for AES-GCM
			},
			cryptoKey,
			cipherByteArray,
		);
		// Convert the decrypted ArrayBuffer to a UTF-8 string
		return new TextDecoder().decode(decryptedBuffer);
	}

	/** Utility: Converts a base64 string to a Uint8Array */
	private base64ToByteArray(base64: string): Uint8Array {
		const binary = atob(base64);
		const bytes = new Uint8Array(binary.length);
		for (let i = 0; i < binary.length; i++) {
			bytes[i] = binary.charCodeAt(i);
		}
		return bytes;
	}
}
