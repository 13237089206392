<div class="text-center" data-tt-tour="UserRequestPasswordReset">
  <h2 class="tt-start-panel-title mb-3"> Request Password Reset </h2>
  <form
    id="requestPasswordForm"
    [formGroup]="requestPasswordForm"
    autocomplete="off"
    (ngSubmit)="sendResetLink()"
    novalidate
    name="requestPasswordForm">
    <fieldset [disabled]="waitingForServer">
      <p-inputGroup id="username" data-tt-tour="UserRequestPasswordReset-Input-Username">
        <p-inputGroupAddon>
          <span class="tt-icon-email fa-fw" aria-hidden="true"></span>
        </p-inputGroupAddon>
        <label for="usernameInput" class="d-none">E-mail Address</label>
        <input
          formControlName="username"
          id="usernameInput"
          type="email"
          pInputText
          class="w-100"
          placeholder="E-mail Address"
          autocomplete="email"
          [ngClass]="{ 'is-invalid' : username.dirty && username.invalid }"/>
      </p-inputGroup>
      @if (username.dirty && username.invalid) {
        <p-message
          severity="error"
          text="Please enter a valid e-mail address."
          styleClass="w-100">
        </p-message>
      }
      <button
        class="mt-3 tt-btn-m tt-btn-create w-100"
        data-tt-tour="UserRequestPasswordReset-Button-SendResetLink"
        id="request-password-button"
        type="submit"
        value="Retrieve Password"
        [disabled]="requestPasswordForm.invalid">
        <span [ngClass]="retrievePasswordButtonClass()"></span>
        Send Reset Link
      </button>
    </fieldset>
  </form>
</div>
