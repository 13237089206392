/* eslint-disable @stylistic/quote-props,@stylistic/quotes,@stylistic/indent */
export const environment = {
  "version": "1.20.8",
  "production": false,
  "serverSchemeSecure": true,
  "serverHost": "staging-server.tasktrain.app",
  "serverPort": "443",
  "graphQLpath": "/graphql",
  "ContentBucketRegion": "us-east-2",
  "BedrockRegion": "us-east-1",
  "ContentBucketName": "tasktrain-content-staging",
  "GoogleAnalyticsCode": "G-CEQ1C9DJEN",
  "HelpCrunchAppId": 2,
  "HelpCrunchSecret": "87zEUc9ZyEBhGYOk5ZzFsltV414Qocss7ZbzG/MA4BKXnHt+MzqJawr6xPVFC3driAG5wAdmU0l5926I0rjHeQ==",
  "StripeKey": "pk_test_Ts4QUeE4Gp6ykLU186N9JeqN00OaAhnwXC",
  "GoogleClientId": "900850068450-3kms08gsspr7a2ceagd00pep9vbn2tpb.apps.googleusercontent.com",
  "GoogleRedirectURI": "http://localhost:4200/assets/scripts/oauth-callback.html"
};
