import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServerError } from '@apollo/client/core';
import { ErrorResponse } from '@apollo/client/link/error';
import { WA_LOCATION } from '@ng-web-apis/common';
import { ConfirmationService } from 'primeng/api';

import { ResponseStatus } from '@tasktrain/shared';

import { AppRoutingPaths, AuthRoutingPaths } from '../../../app-routing.module';
import { MessageService } from '../message-service/message.service';
import { UserNotificationMessage, UserNotificationType } from '../message-service/user-notification-message.model';
import { UserSignOutMessage } from '../message-service/user-sign-out-message.model';
import { AuthenticationService } from '../authentication.service';
import { AnalyticsService } from '../analytics.service';


@Injectable({
	providedIn: 'root',
})
export class ErrorLinkService {
	public constructor(
		@Inject(WA_LOCATION) private readonly location: Location,
		private viewRouter: Router,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private authenticationService: AuthenticationService,
		private analyticsService: AnalyticsService,
	) {
	}

	public onError({ graphQLErrors, networkError, forward, operation }: ErrorResponse): void {
		if (graphQLErrors) {
			const errorCount = graphQLErrors.length;
			let errorIterator = 0;
			do {
				const errorStatusCode = graphQLErrors[errorIterator]?.extensions?.statusCode;
				switch (errorStatusCode) {
					case ResponseStatus.Unauthorized:
						void this.requestReauthentication(graphQLErrors[errorIterator].message);
						errorIterator = errorCount;
						break;
					case ResponseStatus.InternalServerError:
						this.reloadApplication(graphQLErrors[errorIterator].message);
						break;
				}
			} while (++errorIterator < errorCount);
		}
		if (networkError) {
			switch ((networkError as ServerError).statusCode) {
				case ResponseStatus.Unauthorized:
					void this.requestReauthentication(networkError.message);
					break;
				case ResponseStatus.BadRequest:
				case ResponseStatus.Forbidden:
				case ResponseStatus.NotFound:
				case ResponseStatus.InternalServerError:
					this.reloadApplication(networkError.message);
					break;
			}
		}
	}

	private async requestReauthentication(userNotificationText: string): Promise<void> {
		const sessionTokens = await this.authenticationService.accountsClient.refreshSession(true);
		if (!sessionTokens) {
			this.messageService.publish(new UserSignOutMessage());
			void this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.SignIn], { queryParams: { returnUrl: this.viewRouter.url } });
			this.messageService.publish(new UserNotificationMessage(userNotificationText, UserNotificationType.Warn));
		}
	}

	private reloadApplication(errorMessage: string): void {
		this.analyticsService.logError(errorMessage, true);
		this.confirmationService.confirm({
			header: 'Oops!',
			message: 'TaskTrain got derailed. Press Continue to get back on track.',
			acceptLabel: 'Continue',
			acceptIcon: 'tt-icon-attention',
			acceptButtonStyleClass: 'tt-btn-update',
			rejectVisible: false,
			accept: (): void => {
				this.viewRouter.navigate(['/'])
					.then((navigationSuccess: boolean) => {
						this.location.reload();
					})
					.catch((error: unknown) => {
						this.location.href = '/';
					});
			},
		});
	}
}
