<div class="text-center" data-tt-tour="UserSignIn">
  <h1 class="tt-start-panel-title mb-3">Sign In</h1>
  <form
    [formGroup]="signInForm"
    id="signInForm"
    (ngSubmit)="onSignInButtonPress()"
    autocomplete="off"
    novalidate
    name="signInForm">
    <fieldset [disabled]="waitingForServer">
      <p-inputGroup id="username" data-tt-tour="UserSignIn-Input-Username">
        <p-inputGroupAddon>
          <span class="tt-icon-email fa-fw" aria-hidden="true"></span>
        </p-inputGroupAddon>
        <input
          formControlName="username"
          id="usernameInput"
          type="email"
          pInputText
          class="w-100"
          placeholder="E-mail Address"
          aria-label="E-mail Address"
          autocomplete="email"
          [ngClass]="{ 'is-invalid' : username.dirty && username.invalid }"/>
      </p-inputGroup>
      @if (username.dirty && username.invalid) {
        <p-message
          severity="error"
          text="Please enter a valid e-mail address"
          styleClass="w-100">
        </p-message>
      }
      <p-inputGroup id="password" class="mt-3" data-tt-tour="UserSignIn-Input-Password">
        <p-inputGroupAddon>
          <span class="tt-icon-password fa-fw" aria-hidden="true"></span>
        </p-inputGroupAddon>
        <input
          formControlName="password"
          id="passwordInput"
          type="password"
          pInputText
          class="w-100"
          placeholder="Password"
          aria-label="Password"
          autocomplete="current-password"
          [ngClass]="{ 'is-invalid' : password.dirty && password.invalid}"/>
      </p-inputGroup>
      @if (password.dirty && password.invalid) {
        <p-message
          severity="error"
				[text]="password.errors.minlength ? 'Password must be 11 characters or longer' :
					password.errors.maxlength ? 'Password must be 222 characters or shorter' :
					password.errors.passwordComplexity ? password.errors.passwordComplexity: ''"
          styleClass="w-100">
        </p-message>
      }
      <div class="mt-3" data-tt-tour="UserSignIn-Button-ForgotPassword">
        <a class="tt-text-hyperlink" id="link" (click)="onForgotPasswordLinkPress()">Forgot password?</a>
      </div>
      <button
        class="mt-3 tt-btn-m tt-btn-create w-100"
        data-tt-tour="UserSignIn-Button-SignIn"
        id="sign-in-button"
        type="submit"
        value="Sign In"
        aria-label="Sign In"
        [disabled]="!signInForm.valid">
        <span
          [ngClass]="{ 'fa fa-lg me-1': true, 'fa-sign-in': !waitingForServer, 'fa-spinner fa-spin': waitingForServer }">
        </span>
        Sign In
      </button>
    </fieldset>
  </form>
</div>
