export enum CategorizationType {
	Sector = 'Sector',
	Function = 'Function'
}

export interface ICategorization {
	name: string;
	description?: string;
	type: CategorizationType;
}
