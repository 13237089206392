import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { populate } from '@tasktrain/shared';

import { IUserCredentials } from '../_routing/user-credentials-resolver.service';
import { validateFormControlEmail } from '../../core/methods/form-validation/validate-form-control-email.method';
import { validateFormControlPasswordComplexity } from '../../core/methods/form-validation/validate-form-control-password-complexity.method';
import { UserNotificationMessage, UserNotificationType } from '../../core/services/message-service/user-notification-message.model';
import { MessageService } from '../../core/services/message-service/message.service';
import { AppRoutingPaths, AuthRoutingPaths } from '../../app-routing.module';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';


@UntilDestroy()
@Component({
	selector: 'tt-user-sign-in',
	templateUrl: 'user-sign-in.component.html',
	styleUrls: ['user-sign-in.component.scss'],
	standalone: false,
})
export class UserSignInComponent implements OnInit {
	public waitingForServer = false;
	public username = new FormControl<string>(undefined, [validateFormControlEmail]);
	public password = new FormControl<string>(undefined, [Validators.minLength(11), Validators.maxLength(222), validateFormControlPasswordComplexity]);
	public signInForm = new FormGroup({
		username: this.username,
		password: this.password,
	});
	private userCredentials: IUserCredentials;
	private returnURL: string;

	public constructor(
		private viewRouter: Router,
		private activatedRoute: ActivatedRoute,
		private messageService: MessageService,
		private userMutationsService: UserMutationsService,
	) {
	}

	public ngOnInit(): void {
		this.returnURL = this.activatedRoute.snapshot.queryParams.returnUrl || AppRoutingPaths.Main;
		// Set reference to UserCredentials object in ActivatedRoute data & initialize form with values
		this.activatedRoute.parent.data
			.subscribe(({ userCredentials }) => {
				this.userCredentials = userCredentials as IUserCredentials;
				Object.keys(this.signInForm.controls).forEach((formControlKey: string) => {
					this.signInForm.patchValue({ [formControlKey]: userCredentials[formControlKey] });
				});
			});
		// Keep UserCredentials object in ActivatedRoute data synchronized to form values
		this.signInForm.valueChanges.pipe(untilDestroyed(this)).subscribe((newUserCredentials: IUserCredentials): void => {
			populate(this.userCredentials, newUserCredentials);
		});
	}

	public onSignInButtonPress(): void {
		if (this.signInForm.valid && !this.waitingForServer) {
			this.waitingForServer = true;
			this.userMutationsService.signIn({ username: this.username.value, password: this.password.value }).subscribe({
				next: () => {
					void this.viewRouter.navigateByUrl(this.returnURL).then((navigationSuccess) => {
						if (!navigationSuccess || !this.viewRouter.url.includes(AppRoutingPaths.Main)) {
							void this.viewRouter.navigate([AppRoutingPaths.Main]);
						}
					});
				},
				error: (error: unknown) => {
					this.waitingForServer = false;
					const errorMessage = error instanceof Error ? error.message : 'Invalid credentials';
					this.messageService.publish(new UserNotificationMessage(errorMessage, UserNotificationType.Error));
				},
				complete: () => {
					this.waitingForServer = false;
				},
			});
		}
	}

	public onForgotPasswordLinkPress(): void {
		void this.viewRouter.navigate([AppRoutingPaths.Auth, AuthRoutingPaths.ForgotPassword]);
	}
}
