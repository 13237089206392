import { AccessTokenResponse, AccessTokenServiceProvider } from '@tasktrain/shared';
import { gql } from 'apollo-angular';

export const AccessTokenRead_Query = gql`
  query AccessTokenReadQuery($accountId: ID!, $serviceProvider: AccessTokenServiceProvider!) {
    AccessTokenReadQuery(accountId: $accountId, serviceProvider: $serviceProvider) {
      key
      secret
			initializer
			expirationDateTime
      serviceProvider
    }
  }
`;

export interface IAccessTokenRead_Variables {
	accountId?: string; // Required for account-based tokens
	serviceProvider: AccessTokenServiceProvider;
}

export interface IAccessTokenRead_ResponseData {
	AccessTokenReadQuery: AccessTokenResponse;
}
