import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserRegisterComponent } from '../user-register/user-register.component';
import { UserSignInComponent } from '../user-sign-in/user-sign-in.component';
import { StartComponent } from '../start/start.component';
import { UserCredentialsResolverService } from './user-credentials-resolver.service';
import { AuthRoutingGuardService } from './auth-routing-guard.service';
import { UserRequestPasswordResetComponent } from '../user-request-password-reset/user-request-password-reset.component';
import { UserSetPasswordComponent } from '../user-set-password/user-set-password.component';
import { VerifyEmailRoutingGuardService } from './verify-email-routing-guard.service';
import { VerifyUsernameRoutingGuardService } from './verify-username-routing-guard.service';
import { AppRoutingPaths, AuthRoutingPaths } from '../../app-routing.module';
import { UserAuthorizeOAuthClientComponent } from '../user-authorize-oauth-client/user-authorize-oauth-client.component';
import { UserAuthorizeOAuthClientRoutingGuardService } from './user-authorize-oauth-client-routing-guard.service';


const routes: Routes = [
	{
		path: AppRoutingPaths.Auth,
		component: StartComponent,
		resolve: { userCredentials: UserCredentialsResolverService },
		children: [
			{
				path: '',
				canActivateChild: [AuthRoutingGuardService],
				children: [
					{ path: '', redirectTo: AuthRoutingPaths.SignIn, pathMatch: 'full' },
					{ path: AuthRoutingPaths.SignIn, component: UserSignInComponent },
					{ path: AuthRoutingPaths.Register, component: UserRegisterComponent },
					{ path: AuthRoutingPaths.ForgotPassword, component: UserRequestPasswordResetComponent },
				],
			},
			{
				path: '',
				children: [
					{ path: `${AuthRoutingPaths.SetPassword}/:token`, component: UserSetPasswordComponent, canActivate: [AuthRoutingGuardService] },
					{ path: `${AuthRoutingPaths.EnrollAccount}/:token`, component: UserSetPasswordComponent, canActivate: [AuthRoutingGuardService] },
				],
			},
			{ path: `${AuthRoutingPaths.VerifyEmail}/:token`, component: UserSignInComponent, canActivate: [VerifyEmailRoutingGuardService] },
			{ path: `${AuthRoutingPaths.VerifyUsername}/:token`, component: UserSignInComponent, canActivate: [VerifyUsernameRoutingGuardService] },
			{ path: AuthRoutingPaths.AuthorizeOAuthClient, component: UserAuthorizeOAuthClientComponent, canActivate: [UserAuthorizeOAuthClientRoutingGuardService] },
		],
	},
];

@NgModule({
	// Providers: Services and Values available to the Angular dependency injector
	providers: [],
	// Declarations: Components, Pipes, Directives belonging to this module & thereby available to others in this module
	declarations: [],
	// Imports: Other Modules whose Declarations should be available to components in this module
	imports: [RouterModule.forChild(routes)],
	// Exports: Components, Pipes, Directives to make available to components declared in Modules importing this module
	exports: [RouterModule],
	// Elements and properties other than components or directives
	schemas: [],
})
export class AuthRoutingModule {
}
