import { Component } from '@angular/core';

@Component({
	selector: 'tt-confirmation-dialog',
	styleUrls: ['confirmation-dialog.component.scss'],
	templateUrl: 'confirmation-dialog.component.html',
	standalone: false,
})
export class ConfirmationDialogComponent {
}
