import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GQLClientQueryService } from '../../services/gql/gql-client-query.service';
import { MessageService } from '../../services/message-service/message.service';
import { IViewStateParametersRead_ResponseData, ViewStateParametersRead_Query } from './view-state-parameters-read.query';
import { ViewStateParameters } from '../../services/view-state/view-state-parameters.model';


/** Service class to invoke real-time updates to `watchQuery` via related Subscription & to centralize any update logic
 *  Prefer to using the query document directly with Apollo Client
 */
@Injectable({ providedIn: 'root' })
export class ViewStateParametersReadService extends GQLClientQueryService<IViewStateParametersRead_ResponseData> {
	public document = ViewStateParametersRead_Query;

	public constructor(
		apollo: Apollo,
		messageService: MessageService,
	) {
		super(apollo, messageService);
	}

	// ** Returns bare `ViewStateParameters` object */
	public mappedWatch(): Observable<ViewStateParameters> {
		return this.watch().valueChanges.pipe(
			map(({ data: { ViewStateParametersRead } }: FetchResult<IViewStateParametersRead_ResponseData>) => {
				return ViewStateParametersRead;
			}),
		);
	}
}
