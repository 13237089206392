import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FetchResult, NextLink, Operation } from '@apollo/client/core';
import { Observable } from '@apollo/client/utilities/observables/Observable';
import { OperationDefinitionNode } from 'graphql';

import { IMutationInProgressListRead_ResponseData, MutationInProgressListRead_Query } from '../../gql-operations/_client/mutation-in-progress-list-read.query';


@Injectable({
	providedIn: 'root',
})
export class MutationMonitorLinkService {
	public constructor(private apollo: Apollo) {
	}

	/**
	 *  Monitor each mutation to enable UI blocking on deletes and saving indicators on Updates
	 */
	public link(operation: Operation, forward: NextLink): Observable<FetchResult> {
		const operationDefinition = operation.query.definitions[0] as OperationDefinitionNode;
		if (operationDefinition.kind === 'OperationDefinition' && operationDefinition.operation === 'mutation') {
			const { query, variables, operationName } = operation;
			const operationKey = JSON.stringify([operationName, query, variables]);
			const trackedMutationInProgressList = this.apollo.client.readQuery<IMutationInProgressListRead_ResponseData>({
				query: MutationInProgressListRead_Query,
			}).MutationInProgressListRead;
			this.apollo.client.writeQuery<IMutationInProgressListRead_ResponseData>({
				query: MutationInProgressListRead_Query,
				data: {
					MutationInProgressListRead: [
						{
							_id: operationKey,
							name: operation.operationName,
							variables: operation.variables,
							status: 'Pending',
							__typename: 'MutationInProgress',
						},
						...trackedMutationInProgressList,
					],
				},
			});

			const mutationInProgressModifier = (id: string, newStatus: 'Pending' | 'Success' | 'Error') => {
				this.apollo.client.cache.modify({
					id: `MutationInProgress:${id}`,
					fields: {
						status: () => {
							return newStatus;
						},
					},
				});
			};

			return new Observable<FetchResult>((originalObserver) => {
				const subscription = forward(operation).subscribe({
					next: (result) => {
						if (!result.errors) {
							mutationInProgressModifier(operationKey, 'Success');
						} else {
							mutationInProgressModifier(operationKey, 'Error');
						}
						originalObserver.next(result);
					},
					error: (error: unknown) => {
						mutationInProgressModifier(operationKey, 'Error');
						originalObserver.error(error);
					},
					complete: () => {
						setTimeout(() => { // Never sends 'Success' if not delayed a tick
							this.apollo.client.cache.evict({
								id: `MutationInProgress:${operationKey}`,
							});
						});
						originalObserver.complete();
					},
				});
				return () => {
					subscription.unsubscribe();
				};
			});
		} else {
			return forward(operation);
		}
	}
}
