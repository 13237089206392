import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class NetworkActivityService {
	private activeRequests = 0;
	private isActiveSubject = new BehaviorSubject<boolean>(false);
	public isActive = this.isActiveSubject.asObservable();

	public increment(): void {
		this.activeRequests++;
		this.updateRequestStatus();
	}

	public decrement(): void {
		if (this.activeRequests > 0) {
			this.activeRequests--;
			this.updateRequestStatus();
		}
	}

	private updateRequestStatus(): void {
		this.isActiveSubject.next(this.activeRequests > 0);
	}
}
