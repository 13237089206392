/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'apollo-angular';
import { OperationDefinitionNode } from 'graphql';

import { DeletedObjectResult } from '@tasktrain/shared';


export const ProcedureStepDelete_Mutation = gql`
	mutation ProcedureStepDelete($accountId: ID!, $procedureId: ID!, $procedureStepId: ID!)  {
		ProcedureStepDelete(
			accountId: $accountId,
			procedureId: $procedureId,
			procedureStepId: $procedureStepId,
		) {
			objectTypeName
			objectId
		}
	}
`;

export const ProcedureStepDelete_Name = (ProcedureStepDelete_Mutation.definitions[0] as OperationDefinitionNode).name?.value;

export interface IProcedureStepDelete_RequestVariables {
	accountId: string;
	procedureId: string;
	procedureStepId: string;
}

export interface IProcedureStepDelete_ResponseData {
	ProcedureStepDelete: DeletedObjectResult;
}
