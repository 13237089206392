import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Message, MessageService as PrimeMessageService } from 'primeng/api';

import { MessageService } from '../../core/services/message-service/message.service';
import { UserNotificationMessage, UserNotificationType } from '../../core/services/message-service/user-notification-message.model';
import { UserSignOutMessage } from '../../core/services/message-service/user-sign-out-message.model';


@UntilDestroy()
@Component({
	selector: 'tt-user-notification',
	styleUrls: ['user-notification.component.scss'],
	templateUrl: 'user-notification.component.html',
	standalone: false,
})
export class UserNotificationComponent implements OnInit {
	public constructor(
		private messageService: MessageService,
		private primeMessageService: PrimeMessageService,
	) {
	}

	public ngOnInit(): void {
		this.displayToastFromUserNotificationMessages();
		this.clearAllToastNotificationsOnUserSignOut();
	}

	private displayToastFromUserNotificationMessages(): void {
		this.messageService.subscribe(UserNotificationMessage)
			.pipe(untilDestroyed(this))
			.subscribe({
				next: (messageContent: UserNotificationMessage): void => {
					let messageDisplayMilliseconds: number = messageContent.displayMilliseconds;
					if (messageDisplayMilliseconds === undefined) {
						switch (messageContent.notificationType) {
							case UserNotificationType.Error:
								messageDisplayMilliseconds = 0;
								break;
							case UserNotificationType.Warn:
								messageDisplayMilliseconds = 30 * 1000;
								break;
							default:
								messageDisplayMilliseconds = 5 * 1000;
								break;
						}
					}
					const newMessage: Message = {
						key: 'toast',
						severity: messageContent.notificationType,
						summary: messageContent.summary || messageContent.notificationType.toLocaleUpperCase(),
						detail: messageContent.detail,
						sticky: !messageDisplayMilliseconds,
						life: messageDisplayMilliseconds ? messageDisplayMilliseconds : 0,
					};
					if (messageContent.detail?.toLocaleLowerCase().includes('sign in')) {
						this.primeMessageService.clear();
					}
					this.primeMessageService.add(newMessage);
				},
			});
	}

	private clearAllToastNotificationsOnUserSignOut(): void {
		this.messageService.subscribe(UserSignOutMessage)
			.pipe(untilDestroyed(this))
			.subscribe({
				next: (messageContent: UserSignOutMessage): void => {
					this.primeMessageService.clear();
				},
			});
	}
}
