import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';

import { UserNotificationComponent } from './user-notification/user-notification.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen-component';
import { DisableControlDirective } from './disable-control/disable-control.directive';


@NgModule({
	// Providers: Services and Values available to the Angular dependency injector
	providers: [],
	// Declarations: Components, Pipes, Directives belonging to this module & thereby available to others in this module
	declarations: [
		ConfirmationDialogComponent,
		DisableControlDirective,
		LoadingScreenComponent,
		UserNotificationComponent,
	],
	// Imports: Other Modules whose Declarations should be available to components in this module
	imports: [
		CommonModule,
		ConfirmDialogModule,
		DialogModule,
		ToastModule,
		MessagesModule,
		MessageModule,
	],
	// Exports: Components, Pipes, Directives to make available to components declared in Modules importing this module
	exports: [
		ConfirmationDialogComponent,
		CommonModule,
		DisableControlDirective,
		FormsModule,
		LoadingScreenComponent,
		ReactiveFormsModule,
		UserNotificationComponent,
	],
	// Elements and properties other than components or directives
	schemas: [],
	// Bootstrap: initial component to load when initializing application
	bootstrap: [],
})
/** Angular Feature Module for components shared among eagerly/lazy-loaded modules */
export class SharedEagerModule {
	/** exports for app.module to register single instance of providers for dependency injection
	 * https://angular-2-training-book.rangle.io/handout/modules/shared-di-tree.html
	 */
	public static forRoot(): ModuleWithProviders<SharedEagerModule> {
		return {
			ngModule: SharedEagerModule,
			providers: [ConfirmationService, MessageService],
		};
	}
}
