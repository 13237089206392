import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IUserCredentials } from '../_routing/user-credentials-resolver.service';
import { IUserSendPasswordReset_ResponseData } from '../../core/gql-operations/user/user-send-password-reset.mutation';
import { UserMutationsService } from '../../core/gql-operations/user/user-mutations.service';


@UntilDestroy()
@Component({
	selector: 'tt-user-request-password-reset',
	templateUrl: 'user-request-password-reset.component.html',
	styleUrls: ['user-request-password-reset.component.scss'],
	standalone: false,
})
export class UserRequestPasswordResetComponent implements OnInit {
	public waitingForServer = false;
	public username = new FormControl<string>(undefined, [Validators.required, Validators.email]);
	public requestPasswordForm = new FormGroup({
		username: this.username,
	});
	private userCredentials: IUserCredentials;

	public constructor(
		private viewRouter: Router,
		private activatedView: ActivatedRoute,
		private apollo: Apollo,
		private userMutationsService: UserMutationsService,
	) {
	}

	public ngOnInit(): void {
		this.activatedView.parent.data
			.subscribe((data: { userCredentials?: IUserCredentials }) => {
				this.userCredentials = data.userCredentials;
			});
		this.requestPasswordForm.valueChanges.pipe(
			untilDestroyed(this),
		).subscribe((data: IUserCredentials): void => {
			this.userCredentials.username = data.username;
		});
	}


	public retrievePasswordButtonClass(): string {
		return (!this.waitingForServer)
			? 'fa fa-sign-in fa-lg me-1'
			: 'fa fa-spinner fa-spin fa-lg me-1';
	}

	public sendResetLink(): void {
		if (!this.waitingForServer) {
			this.waitingForServer = true;
			this.userMutationsService.sendResetLink({ emailAddress: this.username.value }).subscribe({
				next: ({ data }: FetchResult<IUserSendPasswordReset_ResponseData>): void => {
					void this.viewRouter.navigate(['start/sign-in']);
				},
				error: (error: unknown): void => {
					this.waitingForServer = false;
				},
				complete: (): void => {
					this.waitingForServer = false;
				},
			});
		}
	}
}
