import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, Router, Scroll } from '@angular/router';
import { merge } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IMutationInProgressListRead_ResponseData, MutationInProgressListRead_Query } from './core/gql-operations/_client/mutation-in-progress-list-read.query';
import { CommentDelete_Name } from './core/gql-operations/comment-thread/comment-delete.mutation';
import { AssignmentDelete_Name } from './core/gql-operations/assignment/assignment-delete.mutation';
import { OrganizationDelete_Name } from './core/gql-operations/organization/organization-delete.mutation';
import { ManualDelete_Name } from './core/gql-operations/manual/manual-delete.mutation';
import { ProcedureDelete_Name } from './core/gql-operations/procedure/procedure-delete.mutation';
import { ProcedureStepDelete_Name } from './core/gql-operations/procedure-step/procedure-step-delete.mutation';
import { ClientUpdateService } from './core/services/client-update.service';
import { ViewStateService } from './core/services/view-state/view-state.service';


@UntilDestroy()
@Component({
	selector: 'tt-app',
	templateUrl: 'app.component.html',
	encapsulation: ViewEncapsulation.Emulated,
	standalone: false,
})
export class AppComponent implements OnInit {
	public isLoading = true;

	public constructor(
		private viewRouter: Router,
		private changeDetector: ChangeDetectorRef,
		private apollo: Apollo,
		private viewStateService: ViewStateService, // Inject to start at application load
		clientUpdateService: ClientUpdateService, // Inject to start at application load
	) {
	}

	public ngOnInit(): void {
		this.showLoadingAnimationWhenUIShouldBeBlocked();
	}

	private showLoadingAnimationWhenUIShouldBeBlocked(): void {
		const navigationIsInProgress = this.viewRouter.events.pipe(
			map((navigationEvent) => {
				return !(
					navigationEvent instanceof NavigationEnd
					|| navigationEvent instanceof NavigationSkipped
					|| navigationEvent instanceof NavigationCancel
					|| navigationEvent instanceof NavigationError
					|| navigationEvent instanceof Scroll
				);
			}),
		);

		const dangerousMutationIsInProgress = this.apollo.watchQuery<IMutationInProgressListRead_ResponseData>({
			query: MutationInProgressListRead_Query,
		}).valueChanges.pipe(
			map(({ data: { MutationInProgressListRead } }): boolean => {
				if (MutationInProgressListRead) {
					return MutationInProgressListRead.some((mutationInProgress) => {
						return [
							OrganizationDelete_Name,
							ManualDelete_Name,
							ProcedureDelete_Name,
							ProcedureStepDelete_Name,
							AssignmentDelete_Name,
							CommentDelete_Name,
						].includes(mutationInProgress.name);
					});
				} else {
					return false;
				}
			}),
		);

		merge(navigationIsInProgress, dangerousMutationIsInProgress).pipe(
			distinctUntilChanged(),
			untilDestroyed(this),
		).subscribe({
			next: (isLoading) => {
				this.isLoading = isLoading;
				this.changeDetector.detectChanges();
			},
		});
	}
}
