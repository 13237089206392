import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NetworkActivityService } from './network-activity.service';

@Injectable()
export class NetworkActivityInterceptor implements HttpInterceptor {
	public constructor(private networkActivityService: NetworkActivityService) {}

	public intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		this.networkActivityService.increment(); // Increment active requests count
		return httpHandler.handle(httpRequest).pipe(
			finalize(() => this.networkActivityService.decrement()) // Decrement count when request completes
		);
	}
}
