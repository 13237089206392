import { Injectable } from '@angular/core';
import { AccountsClient } from '@accounts/client';
import { CreateUser, ImpersonationUserIdentity } from '@accounts/types';
import { lastValueFrom } from 'rxjs';

import { UserRefreshSessionService } from '../gql-operations/user/user-refresh-session.service';


@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	public accountsClient: AccountsClient; // Use only token methods, as transport methods have no server counterpart

	public constructor(userRefreshSessionService: UserRefreshSessionService) {
		this.accountsClient = new AccountsClient(
			{},
			// Stub implementation of AccountsJS Transport only implementing `refreshSession` since we use our own GraphQL API instead of `@accounts/graphql-api`
			{
				client: this.accountsClient,
				addEmail: (newEmail: string) => {
					return Promise.resolve();
				},
				authenticateWithService: (service: string, authenticateParams: { [p: string]: string | object }) => {
					return Promise.resolve(false);
				},
				changePassword: (oldPassword: string, newPassword: string) => {
					return Promise.resolve();
				},
				createUser: (user: CreateUser) => {
					return Promise.resolve(undefined);
				},
				getUser: () => {
					return Promise.resolve(undefined);
				},
				impersonate: (token: string, impersonated: ImpersonationUserIdentity) => {
					return Promise.resolve(undefined);
				},
				loginWithService: (service: string, authenticateParams: { [p: string]: string | object }) => {
					return Promise.resolve(undefined);
				},
				logout: () => {
					return Promise.resolve();
				},
				refreshTokens: async (accessToken: string, refreshToken: string) => {
					const { data: { UserRefreshSession } } = await lastValueFrom(userRefreshSessionService.mutate({ accessToken, refreshToken }));
					return {
						sessionId: UserRefreshSession.sessionId,
						tokens: { accessToken: UserRefreshSession.accessToken, refreshToken: UserRefreshSession.refreshToken },
						user: {
							id: UserRefreshSession.user._id,
							deactivated: false,
						},
					};
				},
				requestMagicLinkEmail: (email: string) => {
					return Promise.resolve();
				},
				resetPassword: (token: string, newPassword: string) => {
					return Promise.resolve(undefined);
				},
				sendResetPasswordEmail: (email: string) => {
					return Promise.resolve();
				},
				sendVerificationEmail: (email: string) => {
					return Promise.resolve();
				},
				verifyEmail: (token: string) => {
					return Promise.resolve();
				},
			},
		);
	}

	public async isSignedIn(): Promise<boolean> {
		return (await this.accountsClient.getTokens()) !== null;
	}
}
