import { Component } from '@angular/core';

@Component({
	selector: 'tt-loading-screen',
	templateUrl: 'loading-screen.component.html',
	styleUrls: ['loading-screen.component.scss'],
	standalone: false,
})
export class LoadingScreenComponent {
}
