import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApolloLink, Operation, split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { AccountsClient } from '@accounts/client';
import { setContext } from '@apollo/client/link/context';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationDefinitionNode, OperationTypeNode } from 'graphql';
import { Client, CloseCode, createClient } from 'graphql-ws';
import jwtDecode from 'jwt-decode';

import { ResponseStatus } from '@tasktrain/shared';
import { environment } from '@environment/browser';
import { GQLResolversService } from './gql-resolvers.service';
import { MutationMonitorLinkService } from './mutation-monitor-link.service';
import { ErrorLinkService } from './error-link.service';
import { AuthenticationService } from '../authentication.service';
import { DefaultPollingInterval } from '../../types/gql-polling-interval.const';
import { GQLCacheService } from './gql-cache.service';
import { UserRefreshSession_Name } from '../../gql-operations/user/user-refresh-session.mutation';


// We implement our own UserRefreshSession mutation so can't use `accountsLink` from `@accounts/apollo-link`
export const accountsLink = (accountsClientFactory: () => AccountsClient | Promise<AccountsClient>): ApolloLink => {
	return setContext(async (graphQLRequest, context) => {
		const { headers: headersWithoutTokens } = context;
		const accountsClient = await accountsClientFactory();
		const headers = { ...headersWithoutTokens };
		if (graphQLRequest.operationName !== UserRefreshSession_Name) {
			const tokens = await accountsClient.refreshSession();
			if (tokens) {
				headers.Authorization = `Bearer ${tokens.accessToken}`;
			}
		}
		return { headers };
	});
};


@Injectable({
	providedIn: 'root',
})
export class GQLClientService {
	private webSocketClient: Client | undefined;
	private sessionId = `${Math.random().toString(36).replace(/\W+/g, '')}`;

	public constructor(
		private apollo: Apollo,
		private gqlCacheService: GQLCacheService,
		private httpLink: HttpLink,
		private mutationMonitorLinkService: MutationMonitorLinkService,
		private errorLinkService: ErrorLinkService,
		private resolversService: GQLResolversService,
		private authenticationService: AuthenticationService,
	) {
		this.createApolloClient();
	}

	public closeWebSocket(): void | Promise<void> {
		return this.getWebSocketClient().dispose();
	}

	/** configures Apollo client to access GraphQL Application Programming Interface */
	private createApolloClient(): void {
		const httpLink = this.httpLink.create({ uri: `http${environment.serverSchemeSecure ? 's' : ''}://${environment.serverHost}:${environment.serverPort}${environment.graphQLpath}` });
		const webSocketLink = new GraphQLWsLink(this.getWebSocketClient());

		this.apollo.create({
			cache: this.gqlCacheService.initializeCache(),
			defaultOptions: {
				watchQuery: {
					nextFetchPolicy: 'cache-only', // Prevent refetching query on `cache.evict()` while getting cache updates from other requests/explicit refetches
				},
			},
			link: ApolloLink.from([
				accountsLink(() => this.authenticationService.accountsClient),
				new ApolloLink((operation, forward) => {
					// Add sessionId unique to browser tab to allow Subscription filtering
					operation.setContext(({ headers = {} }) => ({
						headers: {
							...headers,
							'session-id': this.sessionId,
						},
					}));
					return forward(operation);
				}),
				onError(this.errorLinkService.onError.bind(this.errorLinkService)),
				new RetryLink({
					delay: {
						initial: 1000,
					},
					attempts: {
						retryIf: (error: unknown, operation: Operation): boolean => {
							const errorStatus = (error as HttpErrorResponse).status;
							return errorStatus === ResponseStatus.Timeout
								|| errorStatus === ResponseStatus.ServiceUnavailable
								|| errorStatus === ResponseStatus.GatewayTimeout
								|| errorStatus === 0;
						},
					},
				}),
				new ApolloLink(this.mutationMonitorLinkService.link.bind(this.mutationMonitorLinkService)),
				split(
					({ query }: Operation) => {
						const { kind, operation }: OperationDefinitionNode = getMainDefinition(query) as OperationDefinitionNode;
						return kind === Kind.OPERATION_DEFINITION && operation === OperationTypeNode.SUBSCRIPTION;
					},
					webSocketLink,
					httpLink,
				),
			]),
			resolvers: this.resolversService.getResolvers(),
		});
	}

	private getWebSocketClient(): Client {
		let accessTokenRefreshTimeoutId: ReturnType<typeof setTimeout>;
		if (!this.webSocketClient) {
			const client = createClient({
				url: `ws${environment.serverSchemeSecure ? 's' : ''}://${environment.serverHost}:${environment.serverPort}${environment.graphQLpath}`,
				lazy: true,
				on: {
					// @ts-ignore - `EventConnectedListener` doesn't include full typings
					connected: async (webSocket: WebSocket) => { /* eslint-disable-line @typescript-eslint/no-misused-promises */
						// Close WebSocket connection at access token expiration to allow new tokens to be added via initial `connectionParams` call
						// https://github.com/enisdenjo/graphql-ws#auth-token
						clearTimeout(accessTokenRefreshTimeoutId);
						const { accessToken } = await this.authenticationService.accountsClient.getTokens();
						const accessTokenExpirationDateTime = jwtDecode<{ exp: number; iat: number }>(accessToken).exp * 1000;
						const milliSecondsUntilAccessTokenExpiration = accessTokenExpirationDateTime - Date.now();
						accessTokenRefreshTimeoutId = setTimeout(() => {
							if (webSocket.readyState === WebSocket.OPEN) {
								webSocket.close(CloseCode.Forbidden, 'Access Token Expiration');
							}
						}, milliSecondsUntilAccessTokenExpiration);
					},
					closed: () => {
						this.apollo.client.defaultOptions.watchQuery.pollInterval = 0;
					},
					error: () => {
						this.apollo.client.defaultOptions.watchQuery.pollInterval = DefaultPollingInterval;
					},
				},
				connectionParams: async () => {
					const sessionTokens = await this.authenticationService.accountsClient.refreshSession();
					return {
						...sessionTokens,
						sessionId: this.sessionId,
					};
				},
			});
			this.webSocketClient = {
				...client,
				dispose: () => {
					this.webSocketClient = undefined;
					void client.dispose();
				},
			};
		}
		return this.webSocketClient;
	}
}
