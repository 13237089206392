import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { WatchQueryOptionsAlone } from 'apollo-angular/types';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { clone } from 'lodash-es';

import { AccessTokenResponse, AccessTokenServiceProvider } from '@tasktrain/shared';
import { AccessTokenRead_Query, IAccessTokenRead_ResponseData, IAccessTokenRead_Variables } from './access-token-read.query';
import { GQLClientQueryService } from '../../services/gql/gql-client-query.service';
import { CryptoService } from '../../services/crypto.service';
import { MessageService } from '../../services/message-service';


@Injectable({ providedIn: 'root' })
export class AccessTokenReadService extends GQLClientQueryService<IAccessTokenRead_ResponseData, IAccessTokenRead_Variables> {
	public document = AccessTokenRead_Query;
	private providerTokenMap: Map<
		AccessTokenServiceProvider,
		AccessTokenResponse
	> = new Map();

	public constructor(
		private cryptoService: CryptoService,
		protected apollo: Apollo,
		protected messageService: MessageService,
	) {
		super(apollo, messageService);
	}

	public fetchDecryptedToken(variables: IAccessTokenRead_Variables): Observable<AccessTokenResponse> {
		const existingTokenData = this.providerTokenMap.get(variables.serviceProvider);
		if (
			existingTokenData
			&& (!existingTokenData.expirationDateTime || new Date(existingTokenData.expirationDateTime) > new Date())
		) {
			return of(existingTokenData);
		} else {
			return this.fetch(variables).pipe(
				switchMap(async ({ data: { AccessTokenReadQuery } }) => {
					const decryptedAccessTokenResponse = clone(AccessTokenReadQuery);
					decryptedAccessTokenResponse.secret = await this.cryptoService.decryptAESGCM(
						AccessTokenReadQuery.secret,
						AccessTokenReadQuery.initializer,
						variables.accountId,
					);
					this.providerTokenMap.set(variables.serviceProvider, decryptedAccessTokenResponse);
					return decryptedAccessTokenResponse;
				}),
				catchError((error: unknown) => {
					return throwError(() =>
						error instanceof Error ? error?.message : error.toString(),
					);
				}),
			);
		}
	}

	public watch(
		variables?: IAccessTokenRead_Variables,
		options?: WatchQueryOptionsAlone<IAccessTokenRead_Variables, IAccessTokenRead_ResponseData>,
	): QueryRef<IAccessTokenRead_ResponseData, IAccessTokenRead_Variables> {
		return super.watch(variables, options);
	}
}
