import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { ApolloCache, FetchResult, Reference } from '@apollo/client/core';
import { StorageType } from '@apollo/client/cache/inmemory/policies';
import { CanReadFunction, ReadFieldFunction, ToReferenceFunction } from '@apollo/client/cache/core/types/common';
import { isReference } from '@apollo/client/utilities/graphql/storeUtils';

import { DomainEntityTypeName } from '@tasktrain/shared';

import { AccountUpdateEmailAddress_Mutation, IAccountUpdateEmailAddress_RequestVariables, IAccountUpdateEmailAddress_ResponseData } from './account-update-email-address.mutation';
import { AccountUpdateDisplayName_Mutation, IAccountUpdateDisplayName_RequestVariables, IAccountUpdateDisplayName_ResponseData } from './account-update-display-name.mutation';
import { AccountRequestVerification_Mutation, IAccountRequestVerification_RequestVariables, IAccountRequestVerification_ResponseData } from './account-request-verification.mutation';
import { AccountUpdateRole_Mutation, IAccountUpdateRole_RequestVariables, IAccountUpdateRole_ResponseData } from './account-update-role.mutation';
import { MessageService } from '../../services/message-service/message.service';
import { UserNotificationMessage, UserNotificationType } from '../../services/message-service/user-notification-message.model';
import { getGQLErrorMessage } from '../../methods/get-gql-error-message.method';
import { AccountUpdateAssignmentViewStatus_Mutation, IAccountUpdateAssignmentViewStatus_RequestVariables, IAccountUpdateAssignmentViewStatus_ResponseData } from './account-update-assignment-view-status.mutation';
import {
	AccountUpdateCommentThreadViewStatus_Mutation,
	IAccountUpdateCommentThreadViewStatus_RequestVariables,
	IAccountUpdateCommentThreadViewStatus_ResponseData,
} from './account-update-comment-thread-view-status-mutation';
import { AccountUpdateVerification_Mutation, IAccountUpdateVerification_RequestVariables, IAccountUpdateVerification_ResponseData } from './account-update-verification.mutation';
import {
	AccountUpdateAssignmentArchiveStatus_Mutation,
	IAccountUpdateAssignmentArchiveStatus_RequestVariables,
	IAccountUpdateAssignmentArchiveStatus_ResponseData,
} from './account-update-assignment-archive-status.mutation';
import {
	AccountUpdateCommentThreadArchiveStatus_Mutation,
	IAccountUpdateCommentThreadArchiveStatus_RequestVariables,
	IAccountUpdateCommentThreadArchiveStatus_ResponseData,
} from './account-update-comment-thread-archive-status.mutation';
import {
	AccountUpdateEmailNotificationTypeList_Mutation,
	IAccountUpdateEmailNotificationTypeList_RequestVariables,
	IAccountUpdateEmailNotificationTypeList_ResponseData,
} from './account-update-email-notification-type-list.mutation';


// @ToDo: Replace usages & removed when type provided by `@apollo/client` via https://github.com/apollographql/apollo-client/pull/7133
interface IModifierUtility {
	DELETE: any;
	INVALIDATE: any;
	fieldName: string;
	storeFieldName: string;
	readField: ReadFieldFunction;
	canRead: CanReadFunction;
	isReference: typeof isReference;
	toReference: ToReferenceFunction;
	storage: StorageType;
}

@Injectable({
	providedIn: 'root',
})
export class AccountMutationsService {
	public constructor(
		private apollo: Apollo,
		private messageService: MessageService,
	) {
	}

	public requestVerification(mutationVariables: IAccountRequestVerification_RequestVariables): Observable<MutationResult<IAccountRequestVerification_ResponseData>> {
		return this.apollo.mutate<IAccountRequestVerification_ResponseData, IAccountRequestVerification_RequestVariables>({
			mutation: AccountRequestVerification_Mutation,
			variables: {
				accountId: mutationVariables.accountId,
			},
		}).pipe(
			tap(({ data }) => {
				this.notifyUser('Reminder sent!', UserNotificationType.Success);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateVerification(mutationVariables: IAccountUpdateVerification_RequestVariables): Observable<MutationResult<IAccountUpdateVerification_ResponseData>> {
		return this.apollo.mutate<IAccountUpdateVerification_ResponseData, IAccountUpdateVerification_RequestVariables>({
			mutation: AccountUpdateVerification_Mutation,
			variables: {
				verificationToken: mutationVariables.verificationToken,
			},
		}).pipe(
			tap(({ data }) => {
				this.notifyUser('E-mail address verified. Thank you!', UserNotificationType.Success);
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateEmailAddress(mutationVariables: IAccountUpdateEmailAddress_RequestVariables): Observable<MutationResult<IAccountUpdateEmailAddress_ResponseData>> {
		return this.apollo.mutate<IAccountUpdateEmailAddress_ResponseData, IAccountUpdateEmailAddress_RequestVariables>({
			mutation: AccountUpdateEmailAddress_Mutation,
			variables: mutationVariables,
		}).pipe(
			tap(({ data: { AccountUpdateEmailAddress } }) => {
				if (!AccountUpdateEmailAddress.isVerified) {
					this.notifyUser('Please check your e-mail to verify your new address', UserNotificationType.Info);
				}
			}),
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateDisplayName(mutationVariables: IAccountUpdateDisplayName_RequestVariables): Observable<MutationResult<IAccountUpdateDisplayName_ResponseData>> {
		return this.apollo.mutate<IAccountUpdateDisplayName_ResponseData, IAccountUpdateDisplayName_RequestVariables>({
			mutation: AccountUpdateDisplayName_Mutation,
			variables: mutationVariables,
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateRole(mutationVariables: IAccountUpdateRole_RequestVariables): Observable<MutationResult<IAccountUpdateRole_ResponseData>> {
		return this.apollo.mutate<IAccountUpdateRole_ResponseData, IAccountUpdateRole_RequestVariables>({
			mutation: AccountUpdateRole_Mutation,
			variables: {
				accountId: mutationVariables.accountId,
				organizationRole: mutationVariables.organizationRole,
			},
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateAssignmentArchiveStatus(mutationVariables: IAccountUpdateAssignmentArchiveStatus_RequestVariables): Observable<
		MutationResult<IAccountUpdateAssignmentArchiveStatus_ResponseData>
	> {
		return this.apollo.mutate<IAccountUpdateAssignmentArchiveStatus_ResponseData, IAccountUpdateAssignmentArchiveStatus_RequestVariables>({
			mutation: AccountUpdateAssignmentArchiveStatus_Mutation,
			variables: mutationVariables,
			update: (
				apolloClientCache: ApolloCache<IAccountUpdateAssignmentArchiveStatus_ResponseData>,
				{ data: { AccountUpdateAssignmentArchiveStatus } }: FetchResult<IAccountUpdateAssignmentArchiveStatus_ResponseData>,
			) => {
				if (AccountUpdateAssignmentArchiveStatus) {
					const updatedAssignmentWithAccountViewStatusReference = apolloClientCache.writeFragment({
						data: AccountUpdateAssignmentArchiveStatus,
						fragment: gql`fragment AssignmentWithAccountViewStatusReference on AssignmentWithAccountViewStatus {
							_id
						}`,
					});
					apolloClientCache.modify<{ assignmentWithViewStatusList: Reference[] }>({
						id: `${DomainEntityTypeName.Account}:${mutationVariables.accountId}`,
						fields: {
							assignmentWithViewStatusList: (assignmentWithAccountViewStatusReferenceList, { storeFieldName }: IModifierUtility) => {
								if (storeFieldName.includes(AccountUpdateAssignmentArchiveStatus.viewStatus)) {
									return assignmentWithAccountViewStatusReferenceList.some((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref === `${AccountUpdateAssignmentArchiveStatus.__typename}:${AccountUpdateAssignmentArchiveStatus._id}`;
									})
										? assignmentWithAccountViewStatusReferenceList
										: [...assignmentWithAccountViewStatusReferenceList, updatedAssignmentWithAccountViewStatusReference];
								} else {
									return assignmentWithAccountViewStatusReferenceList.filter((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref !== `${AccountUpdateAssignmentArchiveStatus.__typename}:${AccountUpdateAssignmentArchiveStatus._id}`;
									});
								}
							},
						},
					});
				}
			},
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateAssignmentViewStatus(mutationVariables: IAccountUpdateAssignmentViewStatus_RequestVariables): Observable<MutationResult<IAccountUpdateAssignmentViewStatus_ResponseData>> {
		return this.apollo.mutate<IAccountUpdateAssignmentViewStatus_ResponseData, IAccountUpdateAssignmentViewStatus_RequestVariables>({
			mutation: AccountUpdateAssignmentViewStatus_Mutation,
			variables: mutationVariables,
			update: (
				apolloClientCache: ApolloCache<IAccountUpdateAssignmentViewStatus_ResponseData>,
				{ data: { AccountUpdateAssignmentViewStatus } }: FetchResult<IAccountUpdateAssignmentViewStatus_ResponseData>,
			) => {
				if (AccountUpdateAssignmentViewStatus) {
					const updatedAssignmentWithAccountViewStatusReference = apolloClientCache.writeFragment({
						data: AccountUpdateAssignmentViewStatus,
						fragment: gql`fragment AssignmentWithAccountViewStatusReference on AssignmentWithAccountViewStatus {
							_id
						}`,
					});
					apolloClientCache.modify<{ assignmentWithViewStatusList: Reference[] }>({
						id: `${DomainEntityTypeName.Account}:${mutationVariables.accountId}`,
						fields: {
							assignmentWithViewStatusList: (assignmentWithAccountViewStatusReferenceList, { storeFieldName }: IModifierUtility) => {
								if (storeFieldName.includes(AccountUpdateAssignmentViewStatus.viewStatus)) {
									return assignmentWithAccountViewStatusReferenceList.some((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref === `${AccountUpdateAssignmentViewStatus.__typename}:${AccountUpdateAssignmentViewStatus._id}`;
									})
										? assignmentWithAccountViewStatusReferenceList
										: [...assignmentWithAccountViewStatusReferenceList, updatedAssignmentWithAccountViewStatusReference];
								} else {
									return assignmentWithAccountViewStatusReferenceList.filter((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref !== `${AccountUpdateAssignmentViewStatus.__typename}:${AccountUpdateAssignmentViewStatus._id}`;
									});
								}
							},
						},
					});
				}
			},
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateCommentThreadArchiveStatus(mutationVariables: IAccountUpdateCommentThreadArchiveStatus_RequestVariables): Observable<
		MutationResult<IAccountUpdateCommentThreadArchiveStatus_ResponseData>
	> {
		return this.apollo.mutate<IAccountUpdateCommentThreadArchiveStatus_ResponseData, IAccountUpdateCommentThreadArchiveStatus_RequestVariables>({
			mutation: AccountUpdateCommentThreadArchiveStatus_Mutation,
			variables: mutationVariables,
			update: (
				apolloClientCache: ApolloCache<IAccountUpdateCommentThreadArchiveStatus_ResponseData>,
				{ data: { AccountUpdateCommentThreadArchiveStatus } }: FetchResult<IAccountUpdateCommentThreadArchiveStatus_ResponseData>,
			) => {
				if (AccountUpdateCommentThreadArchiveStatus) {
					const updatedCommentThreadWithAccountViewStatusReference = apolloClientCache.writeFragment({
						data: AccountUpdateCommentThreadArchiveStatus,
						fragment: gql`fragment CommentThreadWithAccountViewStatusReference on CommentThreadWithAccountViewStatus {
							_id
						}`,
					});
					apolloClientCache.modify<{ commentThreadWithViewStatusList: Reference[] }>({
						id: `${DomainEntityTypeName.Account}:${mutationVariables.accountId}`,
						fields: {
							commentThreadWithViewStatusList: (commentThreadWithAccountViewStatusReferenceList, { storeFieldName }: IModifierUtility) => {
								if (storeFieldName.includes(AccountUpdateCommentThreadArchiveStatus.viewStatus)) {
									return commentThreadWithAccountViewStatusReferenceList.some((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref === `${AccountUpdateCommentThreadArchiveStatus.__typename}:${AccountUpdateCommentThreadArchiveStatus._id}`;
									})
										? commentThreadWithAccountViewStatusReferenceList
										: [...commentThreadWithAccountViewStatusReferenceList, updatedCommentThreadWithAccountViewStatusReference];
								} else {
									return commentThreadWithAccountViewStatusReferenceList.filter((assignmentWithAccountArchiveStatusReference) => {
										return assignmentWithAccountArchiveStatusReference.__ref !== `${AccountUpdateCommentThreadArchiveStatus.__typename}:${AccountUpdateCommentThreadArchiveStatus._id}`;
									});
								}
							},
						},
					});
				}
			},
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateCommentThreadViewStatus(mutationVariables: IAccountUpdateCommentThreadViewStatus_RequestVariables): Observable<
		MutationResult<IAccountUpdateCommentThreadViewStatus_ResponseData>
	> {
		return this.apollo.mutate<IAccountUpdateCommentThreadViewStatus_ResponseData, IAccountUpdateCommentThreadViewStatus_RequestVariables>({
			mutation: AccountUpdateCommentThreadViewStatus_Mutation,
			variables: mutationVariables,
			update: (
				apolloClientCache: ApolloCache<IAccountUpdateCommentThreadViewStatus_ResponseData>,
				{ data: { AccountUpdateCommentThreadViewStatus } }: FetchResult<IAccountUpdateCommentThreadViewStatus_ResponseData>,
			) => {
				if (AccountUpdateCommentThreadViewStatus) {
					const updatedCommentThreadWithAccountViewStatusReference = apolloClientCache.writeFragment({
						data: AccountUpdateCommentThreadViewStatus,
						fragment: gql`fragment CommentThreadWithAccountViewStatusReference on CommentThreadWithAccountViewStatus {
							_id
						}`,
					});
					apolloClientCache.modify<{ commentThreadWithViewStatusList: Reference[] }>({
						id: `${DomainEntityTypeName.Account}:${mutationVariables.accountId}`,
						fields: {
							commentThreadWithViewStatusList: (commentThreadWithAccountViewStatusReferenceList, { storeFieldName }: IModifierUtility) => {
								if (storeFieldName.includes(AccountUpdateCommentThreadViewStatus.viewStatus)) {
									return commentThreadWithAccountViewStatusReferenceList.some((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref === `${AccountUpdateCommentThreadViewStatus.__typename}:${AccountUpdateCommentThreadViewStatus._id}`;
									})
										? commentThreadWithAccountViewStatusReferenceList
										: [...commentThreadWithAccountViewStatusReferenceList, updatedCommentThreadWithAccountViewStatusReference];
								} else {
									return commentThreadWithAccountViewStatusReferenceList.filter((assignmentWithAccountViewStatusReference) => {
										return assignmentWithAccountViewStatusReference.__ref !== `${AccountUpdateCommentThreadViewStatus.__typename}:${AccountUpdateCommentThreadViewStatus._id}`;
									});
								}
							},
						},
					});
				}
			},
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	public updateEmailNotificationTypeList(
		mutationVariables: IAccountUpdateEmailNotificationTypeList_RequestVariables,
	): Observable<MutationResult<IAccountUpdateEmailNotificationTypeList_ResponseData>> {
		return this.apollo.mutate<IAccountUpdateEmailNotificationTypeList_ResponseData, IAccountUpdateEmailNotificationTypeList_RequestVariables>({
			mutation: AccountUpdateEmailNotificationTypeList_Mutation,
			variables: mutationVariables,
		}).pipe(
			catchError((error: unknown) => {
				this.notifyUser(getGQLErrorMessage(error), UserNotificationType.Error);
				return throwError(() => error);
			}),
		);
	}

	private notifyUser(message: string, notificationType: UserNotificationType, summary?: string): void {
		this.messageService.publish(new UserNotificationMessage(message, notificationType, summary));
	}
}
